import React from "react";
import {
  VStack,
  Text,
  Container,
  HStack,
  Icon,
  Image,
  Heading,
  Link,
  Center,
} from "@chakra-ui/react";
import {
  FiFacebook,
  FiTwitter,
  FiPhone,
  FiMail,
  FiMapPin,
} from "react-icons/fi";
import { BsInstagram } from "react-icons/bs";
import qr from "../assets/images/qr.png";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  
  return (
    <VStack w={"full"} spacing={0}>
      <VStack
        bg={"transparent"}
        w={"full"}
        // pt={"6"}
        display={{
          base: "none",
          md: "none",
          lg:
            location.pathname === "/contact" ||
            // location.pathname === "/product" ||
            location.pathname === "/instruction"
              ? "none"
              : "flex",
        }}
      >
        <Container maxW={"container.xl"} centerContent py={"8"}>
          <HStack justify={"space-between"} align={"center"} w={"full"}>
            <Heading
              as="h4"
              size="md"
              fontWeight={"medium"}
              alignSelf={"flex-start"}
              mb={"4"}
            >
              Бидэнтэй холбогдох
            </Heading>
            <Heading
              as="h4"
              size="md"
              fontWeight={"medium"}
              alignSelf={"flex-start"}
              mb={"4"}
            >
              Аппликейшн татах
            </Heading>
          </HStack>

          <HStack w={"full"} justify={"space-between"}>
            {/* ----------- Social --------------- */}
            <VStack align={"flex-start"} spacing={4}>
              <HStack>
                <Icon as={FiFacebook} color={"#000"} w={6} h={6} />

                <Link href="https://www.facebook.com/viot.mon/" isExternal>
                  <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
                    www.facebook.com/viot.mon
                  </Text>
                </Link>
              </HStack>
              <HStack>
                <Icon as={BsInstagram} color={"#000"} w={6} h={6} />
                <Link href="https://www.instagram.com" isExternal>
                  <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
                    www.instagram.com/viot/
                  </Text>
                </Link>
              </HStack>
              <HStack>
                <Icon as={FiTwitter} color={"#000"} w={6} h={6} />
                <Link href="https://www.twitter.com" isExternal>
                  <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
                    www.twiter.com/viot.mon
                  </Text>
                </Link>
              </HStack>
            </VStack>

            {/* ----------- Contact --------------- */}
            <VStack align={"flex-start"} spacing={4}>
              <HStack>
                <Icon as={FiPhone} color={"#000"} w={6} h={6} />
                <Link href="tel:+97672722072">
                  <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
                    Утас: +976 72722072
                  </Text>
                </Link>
              </HStack>
              <HStack>
                <Icon as={FiMail} color={"#000"} w={6} h={6} />
                <Link href="mailto:info@viot.mn">
                  <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
                    И-мэйл хаяг: info@viot.mn
                  </Text>
                </Link>
              </HStack>
              <HStack>
                <Icon as={FiMapPin} color={"#000"} w={6} h={6} />
                <Link
                  href="https://what3words.com/%D0%B4%D0%BE%D1%82%D0%BD%D0%BE.%D1%85%D3%A9%D0%B3%D0%B6%D0%B8%D0%BD%D3%A9.%D0%B3%D1%8D%D0%BC%D0%B3%D2%AF%D0%B9"
                  isExternal
                >
                  <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
                    Хаяг: ///дотно.хөгжинө.гэмгүй
                  </Text>
                </Link>
              </HStack>
            </VStack>

            {/* ----------- Apps --------------- */}
            <Image src={qr} objectFit={"contain"} w={"32"} />
          </HStack>
        </Container>
      </VStack>
      <Center
        bg={"#F9F9F9"}
        py={"6"}
        w={"full"}
        display={{ base: "none", md: "none", lg: "flex" }}
      >
        <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
          Developed by{" "}
          <Text
            as="span"
            color={"#000"}
            fontSize={15}
            fontWeight={"medium"}
            textTransform={"uppercase"}
          >
            nuden solution llc
          </Text>
        </Text>
      </Center>
    </VStack>
  );
};

export default Footer;
