export const navData = [
  {
    title: "Нүүр хуудас",
    toLink: "/home",
  },
  {
    title: "Төхөөрөмжүүд",
    toLink: "/product",
  },
  {
    title: "Ашиглах заавар",
    toLink: "/instruction",
  },
  {
    title: "Түгээмэл асуулт",
    toLink: "/question",
  },
  {
    title: "Холбоо барих",
    toLink: "/contact",
  },
];
