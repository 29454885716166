import React, { useState } from "react";
import {
  Box,
  Center,
  Flex,
  HStack,
  Image,
  keyframes,
  Link,
  Text,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { navData } from "./NavbarData";
import logo from "../../assets/images/logo.png";
import { IoIosArrowForward } from "react-icons/io";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import NavbarMobile from "./NavbarMobile";

const Navbar = () => {
  const [navSroll, setNavScroll] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const [navHover, setNavHover] = useState("");
  const history = useHistory();
  const location = useLocation();

  const changeShadow = () => {
    if (window.scrollY >= 80) {
      setNavScroll(true);
    } else {
      setNavScroll(false);
    }
  };

  window.addEventListener("scroll", changeShadow);

  const MobileLogoKeyframes = keyframes`
  0% { transform: translate(0px, -50px); opacity: 0; }
  100% { transform: translate(0px, 0px); opacity: 1;}
`;

  const MobileLogo = `${MobileLogoKeyframes} 0.8s ease-in-out`;

  const LogoKeyframes = keyframes`
      0% { transform: translate(-100px, 0px); opacity: 0; }
      100% { transform: translate(0px, 0px); opacity: 1;}
  `;

  const LogoAnimation = `${LogoKeyframes} 1s ease-in-out`;

  const MenuKeyframes = keyframes`
      0% { opacity: 0; scale: 0.8}
      100% { opacity: 1; scale: 1}
  `;

  const MenuAnimation = `${MenuKeyframes} 1s ease-in-out`;

  const BtnKeyframes = keyframes`
  0% { transform: translate(100px, 0px); opacity: 0; }
  100% { transform: translate(0px, 0px); opacity: 1;}
`;

  const BtnAnimation = `${BtnKeyframes} 1s ease-in`;

  const [showSideBar, setShowSidebar] = useState(false);

  const { isOpen, onClose } = useDisclosure();

  const toggleSidebar = () => {
    if (showSideBar === true) {
      setShowSidebar(onClose);
    } else {
      setShowSidebar(!isOpen);
    }
  };

  return (
    <Flex
      transition={"ease-in-out .3s"}
      bg={navSroll ? "#fff" : "transparent"}
      w={"full"}
      // px={"96"}
      px={{ base: "4", md: "10", lg: "40" }}
      pos={"relative"}
      shadow={{ base: "md", md: navSroll ? "md" : "none" }}
      position={
        location.pathname === "/home"
          ? { base: "initial", md: "initial", lg: "fixed" }
          : "initial"
      }
      zIndex={"sticky"}
    >
      <NavbarMobile showSideBar={showSideBar} toggleSidebar={toggleSidebar} />
      <Flex
        w={"full"}
        h={"14"}
        display={{ base: "flex", md: "flex", lg: "none" }}
        align={"center"}
        justify={"center"}
        pos={"relative"}
      >
        <Icon
          as={HiOutlineMenuAlt1}
          w={6}
          h={6}
          color="#000"
          onClick={toggleSidebar}
          pos={"absolute"}
          left={0}
        />
        <Image
          animation={MobileLogo}
          src={logo}
          w={"32"}
          cursor={"pointer"}
          onClick={() => history.push("/home")}
        />
      </Flex>
      <HStack
        align={"center"}
        justify={"space-between"}
        w={"full"}
        py={"4"}
        display={{ base: "none", md: "none", lg: "flex" }}
      >
        <Image
          animation={LogoAnimation}
          src={logo}
          w={"44"}
          cursor={"pointer"}
          onClick={() => history.push("/home")}
        />
        <HStack spacing={10}>
          {navData.map((e, index) => (
            <NavLink key={index} to={e.toLink}>
              <HStack
                animation={MenuAnimation}
                onMouseOut={() => setNavHover("")}
                onMouseOver={() => setNavHover(e.title)}
              >
                <Box
                  w={"1.5"}
                  h={"1.5"}
                  rounded={"full"}
                  bg={navHover === e.title ? "#45A735" : "transparent"}
                  transition={"ease-in-out .3s"}
                />
                <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
                  {e.title}
                </Text>
              </HStack>
            </NavLink>
          ))}
        </HStack>

        <Link href="https://web.viot.mn/" isExternal>
          <Center
            animation={BtnAnimation}
            borderWidth={"thin"}
            borderColor={"#45A735"}
            rounded={"md"}
            w={"40"}
            py={"2"}
            // px={"6"}
            onMouseOut={() => setShowArrow(false)}
            onMouseOver={() => setShowArrow(true)}
            // transition={"ease-in-out .5s"}
          >
            <Text
              color={"#000"}
              fontSize={15}
              fontWeight={"normal"}
              ml={showArrow ? "0" : "5"}
              transition={"ease-in-out .3s"}
            >
              Бүртгүүлэх
            </Text>
            <Icon
              as={IoIosArrowForward}
              ml={"2"}
              w={"4"}
              h={"4"}
              // display={showArrow ? "block" : "none"}
              opacity={showArrow ? 1 : 0}
              transition={"ease-in-out .3s"}
            />
          </Center>
        </Link>
      </HStack>
    </Flex>
  );
};

export default Navbar;
