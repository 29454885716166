import axios from "axios";
const source = axios.CancelToken.source();
let instance;
instance = axios.create({
  baseURL: "https://www.viot.mn:4545",
  // baseURL: "http://192.168.1.136:4545",
  cancelToken: source.token,
});
instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

export default instance;
