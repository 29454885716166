import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";

const Question = () => {
  return (
    <VStack
      w={{ base: "xs", md: "xl", lg: "container.xl" }}
      spacing={{ base: 4, md: 8 }}
      minH={"calc(100vh - 85px - 300px)"}
    >
      <Heading
        as="h3"
        size={{ base: "md", md: "lg" }}
        fontWeight={"medium"}
        alignSelf={"flex-start"}
        mt={"10"}
      >
        Түгээмэл асуултууд
      </Heading>
      <Accordion allowMultiple w={"full"}>
        {question.map((e, index) => (
          <AccordionItem
            key={index}
            border={"none"}
            pb={2}
            as={motion.div}
            variants={{
              offscreen: {
                opacity: 0,
                y: 200,
              },
              onscreen: {
                opacity: 1,
                y: 0,
                transition: {
                  // type: "easeInOut",
                  // bounce: 0.4,
                  duration: 0.8,
                  delay: index * 0.2,
                },
              },
            }}
            initial="offscreen"
            animate="onscreen"
          >
            <h2>
              <AccordionButton
                justifyContent={"space-between"}
                px={{ base: "4", md: "10", lg: "40" }}
                bg={"#FBFBFB"}
                rounded={"md"}
                border={"none"}
              >
                <HStack spacing={3}>
                  <Box
                    w={"2"}
                    h={"2"}
                    bg={"#fff"}
                    rounded={"full"}
                    borderWidth={"thin"}
                    borderColor={"#45A735"}
                  />
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={15}
                    fontWeight={"medium"}
                    color={"#000"}
                  >
                    {e.question}
                  </Box>
                </HStack>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel
              px={{ base: "4", md: "10", lg: "40" }}
              fontSize={15}
              fontWeight={"normal"}
              color={"#000"}
              py={"3"}
            >
              {e.answer}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </VStack>
  );
};

const question = [
  {
    question: "Virtuoso Internet Of Things /VIOT/ гэж юу вэ?",
    answer:
      "Хүн ба Электрон тоног төхөөрөмж хооронд мэдээлэл дамжуулах зорилготой технологи юм. Мөн интернэт холбогдох чадамжтай тоног төхөөрөмжүүд тэдгээрийн цогц ажиллагаа бөгөөд өөрөөр хэлбэл төхөөрөмжүүд хоорондоо танаас хамааралгүйгээр холбогдон хугацааны хоцрогдолгүй мэдээллээр хангах боломжийг бүрдүүлдэг.",
  },
  {
    question: "Системийг хэрхэн хэрэглэх вэ?",
    answer:
      "Төхөөрөмж нь интернэт ашиглан өгөгдөл мэдээлэл дамжуулах болон үүрэг гүйцэтгэн хослуулан хэрэглэх боломжтой. ",
  },
  {
    question: "Төхөөрөмжүүд ямар үнэтэй байдаг вэ?",
    answer:
      "Төхөөрөмж суурилуулах гэж байгаа газарт очин үзлэг шалгалт хийж танд ямар төхөөрөмж хэрэгтэй байгааг тодорхойлж үнийн санал гаргана. ",
  },
  {
    question: "Хаана ашиглах боломжтой вэ?",
    answer:
      "Гэр орон сууц, Оффис, Үйлчилгээний газар, Хөдөө аж ахуй, Газар тариалан гэх мэт хаана ч ашиглах боломжтой. ",
  },
  {
    question: "Хэр хурдан хугацаанд суурилуулалт хийх вэ?",
    answer:
      "Тог цахилгаан интернэт байхад тухайн өдөрт нь суурилуулалт бүрэн хийгддэг.",
  },
  {
    question: "Төлбөр төлсөн тохиолдолд НӨАТ баримтаа хэрхэн авах вэ?",
    answer:
      "Төлбөр төлсөн тохиолдолд и-баримт автоматаар илгээгдэнэ. Та НӨАТ-ийн баримтаа байгууллагаар авахын тулд төлбөр төлөхдөө гүйлгээний утган дээр байгууллагын регистрийн дугаараа бичсэнээр баримтаа байгууллага дээр авах боломжтой. ",
  },
  {
    question: "Зөвхөн гар утсаараа ашиглах боломжтой юу?",
    answer:
      "Манай систем нь гар утас, таблет, компьютер гээд платформ үл харгалзан ашиглах боломжтой.",
  },
  {
    question: "Шуурхай зөвлөгөө тусламж хэрхэн авах вэ?",
    answer:
      "Та манай 72722072 дугаарт холбогдож өөрт хэрэгтэй мэдээллээ авах боломжтой.",
  },
];

export default Question;
