import { Flex, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/navbar/Navbar";
import UserContext from "../context/UserContext";

const Main = ({ children }) => {
  const { isLoading } = useContext(UserContext);

  return (
    <VStack
      w={"full"}
      bg={"#fff"}
      minH={"100vh"}
      spacing={0}
      position={"relative"}
      // overflowY={{ base: "auto", md: "auto", lg: "auto" }}

      overflow={"hidden"}
      // css={{
      //   "&::-webkit-scrollbar": {
      //     width: "4px",
      //     height: "4px",
      //   },
      //   "&::-webkit-scrollbar-track": {
      //     width: "6px",
      //   },
      //   "&::-webkit-scrollbar-thumb": {
      //     background: "#45A735",
      //     borderRadius: "24px",
      //   },
      // }}
    >
      <Navbar />
      <Flex
        w={{ base: "sm", sm: "md", md: "full" }}
        h={"full"}
        align={"flex-start"}
        justify={"center"}
        // overflowY={"auto"}
        overflow={"hidden"}
        // css={{
        //   "&::-webkit-scrollbar": {
        //     width: "4px",
        //     height: "4px",
        //   },
        //   "&::-webkit-scrollbar-track": {
        //     width: "6px",
        //   },
        //   "&::-webkit-scrollbar-thumb": {
        //     background: "#45A735",
        //     borderRadius: "24px",
        //   },
        // }}
        // overflowY={{ base: "auto", md: "auto", lg: "auto" }}
        // css={{
        //   '&::-webkit-scrollbar': {
        //     width: '4px',
        //   },
        //   '&::-webkit-scrollbar-track': {
        //     width: '6px',
        //   },
        //   '&::-webkit-scrollbar-thumb': {
        //     background: '#ddd',
        //     borderRadius: '24px',
        //   },
        // }}
      >
        {children}
      </Flex>
      {isLoading ? null : <Footer />}
    </VStack>
  );
};

export default Main;
