import {
  HStack,
  Text,
  VStack,
  Icon,
  Link,
  Heading,
  Input,
  Button,
  Image,
  Center,
  Textarea,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  FiFacebook,
  FiTwitter,
  FiPhone,
  FiMail,
  FiMapPin,
} from "react-icons/fi";
import { BsInstagram } from "react-icons/bs";
import bgContact from "../assets/images/bgContact.svg";
import btn from "../assets/icons/btn.png";
import axios from "../axios";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [username, setUsername] = useState("");
  const [mail, setMail] = useState("");
  const [description, setDescription] = useState("");
  const toast = useToast();

  const validEmail = new RegExp(
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  );

  const submitHandler = () => {
    if (!validEmail.test(mail) && username && description) {
      toast({
        duration: 2000,
        position: "top",
        status: "error",
        description: "Хүчинтэй имэйл хаяг оруулна уу!",
      });
    } else {
      axios
        .post("/feedback", {
          name: username,
          email: mail,
          message: description,
        })
        .then((result) => {
          if (result.data.success) {
            toast({
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай илгээлээ!",
            });
            setUsername("");
            setMail("");
            setDescription("");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <VStack
      w={"full"}
      h={{ base: "full", md: "calc(100vh - 85px - 70.5px)" }}
      bg={"#fff"}
      spacing={0}
      // overflow={"hidden"}
    >
      <Helmet>
        <title>Холбоо барих</title>
        <meta
          name="description"
          content="Та манай системийг ашиглан өөрийн гэр, оффис, бизнесийн орчны тодорхой үзүүлэлтүүд болон цахилгаан тоноглолыг хянаж удирдан санаа зовох асуудлаа цөөлөөрэй."
        />
        <link rel="canonical" href="https://viot.mn/contact" />
      </Helmet>
      <VStack
        w={"full"}
        align={"center"}
        justify={"center"}
        backgroundImage={{ base: bgContact, lg: bgContact }}
        backgroundRepeat="no-repeat"
        backgroundSize={"cover"}
        backgroundPosition={"center"}
        py={{ base: "10", md: "16" }}
      >
        <VStack
          spacing={{ base: 4, md: 8 }}
          as={motion.div}
          variants={{
            offscreen: {
              opacity: 0,
              x: 400,
            },
            onscreen: {
              opacity: 1,
              x: 0,
              transition: {
                type: "easeInOut",
                bounce: 0.4,
                duration: 1,
                // delay: index * 0.2,
              },
            },
          }}
          initial="offscreen"
          animate="onscreen"
        >
          <Heading as="h3" size="lg" fontWeight={"medium"}>
            VIOT Аппликейшн
          </Heading>
          <Text>Та дараах хаягуудаар бидэнтэй холбогдоорой</Text>
          <HStack spacing={6}>
            <Link href="https://www.facebook.com/viot.mon/" isExternal>
              <Center
                transition={"ease .3s"}
                rounded={"md"}
                p={"3"}
                color={"#000"}
                _hover={{
                  bg: "#fff",
                  padding: 3,
                  color: "#45A735",
                }}
              >
                <Icon as={FiFacebook} w={8} h={8} />
              </Center>
            </Link>
            <Link href="https://www.facebook.com/viot.mon/" isExternal>
              <Center
                transition={"ease .3s"}
                rounded={"md"}
                p={"3"}
                color={"#000"}
                _hover={{
                  bg: "#fff",
                  padding: 3,
                  color: "#45A735",
                }}
              >
                <Icon as={BsInstagram} w={8} h={8} />
              </Center>
            </Link>
            <Link href="https://web.viot.mn/" isExternal>
              <Center
                transition={"ease .3s"}
                rounded={"md"}
                p={"3"}
                color={"#000"}
                _hover={{
                  bg: "#fff",
                  padding: 3,
                  color: "#45A735",
                }}
              >
                <Icon as={FiTwitter} w={8} h={8} />
              </Center>
            </Link>
          </HStack>
        </VStack>
      </VStack>
      <Stack
        direction={{ base: "column", md: "column", lg: "row" }}
        w={{ base: "xs", md: "xl", lg: "container.xl" }}
        spacing={{ base: 4, md: 0 }}
        h={"full"}
        justify={"space-between"}
        align={"flex-start"}
        as={motion.div}
        variants={{
          offscreen: {
            opacity: 0,
            x: 400,
          },
          onscreen: {
            opacity: 1,
            x: 0,
            transition: {
              type: "easeInOut",
              bounce: 0.4,
              duration: 1,
              // delay: index * 0.2,
            },
          },
        }}
        initial="offscreen"
        animate="onscreen"
      >
        <VStack
          w={"full"}
          h={"full"}
          align={"flex-start"}
          spacing={4}
          mt={"20"}
        >
          <VStack align={"flex-start"}>
            <HStack spacing={4}>
              <Icon as={FiMapPin} color={"#000"} w={6} h={6} />
              <Text color={"#000"} fontSize={17} fontWeight={"medium"}>
                Биднийг хэрхэн олох вэ
              </Text>
            </HStack>
            <Text
              color={"#000"}
              fontSize={15}
              fontWeight={"normal"}
              textAlign={"justify"}
              maxW={"sm"}
            >
              Улаанбаатар хот, Баянгол дүүрэг, 19-р хороо 4-р хороолол Энэбишийн
              78
            </Text>
          </VStack>
          <HStack spacing={4}>
            <Icon as={FiPhone} color={"#000"} w={6} h={6} />
            <Text color={"#000"} fontSize={17} fontWeight={"medium"}>
              Утас: +976 72722072
            </Text>
          </HStack>
          <HStack spacing={4}>
            <Icon as={FiMail} color={"#000"} w={6} h={6} />
            <Text color={"#000"} fontSize={17} fontWeight={"medium"}>
              И-мэйл хаяг: info@viot.mn
            </Text>
          </HStack>
        </VStack>
        <VStack
          w={"full"}
          h={"full"}
          bg={"#FBFBFB"}
          py={"14"}
          px={{ base: "8", md: "20" }}
          align={"center"}
          justify={"center"}
        >
          <Text
            color={"#000"}
            fontSize={17}
            fontWeight={"medium"}
            alignSelf={"flex-start"}
          >
            Санал хүсэлт илгээх
          </Text>
          <VStack w={"full"} spacing={3} mt={"6"}>
            <Stack
              w={"full"}
              align={"center"}
              justify={"center"}
              direction={{ base: "column", md: "row" }}
              spacing={3}
            >
              <Input
                placeholder="Нэр"
                w={"full"}
                bg={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                borderColor={"#f2f2f2"}
                _hover={{
                  borderColor: "#45A735",
                  borderWidth: "thin",
                }}
                _focus={{
                  outline: "none",
                  boxShadow: "none",
                  borderColor: "#45A735",
                  borderWidth: "thin",
                }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <Input
                placeholder="И-мэйл"
                w={"full"}
                bg={"#fff"}
                fontSize={15}
                fontWeight={"normal"}
                borderColor={"#f2f2f2"}
                _hover={{
                  borderColor: "#45A735",
                  borderWidth: "thin",
                }}
                _focus={{
                  outline: "none",
                  boxShadow: "none",
                  borderColor: "#45A735",
                  borderWidth: "thin",
                }}
                value={mail}
                onChange={(e) => setMail(e.target.value)}
              />
            </Stack>
            <Textarea
              placeholder="Санал хүсэлтээ бичнэ үү..."
              w={"full"}
              height={"40"}
              bg={"#fff"}
              fontSize={15}
              fontWeight={"normal"}
              borderColor={"#f2f2f2"}
              _hover={{
                borderColor: "#45A735",
                borderWidth: "thin",
              }}
              _focus={{
                outline: "none",
                boxShadow: "none",
                borderColor: "#45A735",
                borderWidth: "thin",
              }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Button
              w={"full"}
              bg={"#fff"}
              shadow={"sm"}
              onClick={submitHandler}
              disabled={username && mail && description ? false : true}
              _disabled={{
                bg: "#fff",
                cursor: "not-allowed",
              }}
              _hover={{
                bg: "#F5F5F5",
              }}
            >
              Илгээх{" "}
              <Image src={btn} objectFit={"contain"} w={"5"} h={"5"} ml={"4"} />
            </Button>
          </VStack>
        </VStack>
      </Stack>
    </VStack>
  );
};

export default Contact;
