import React from "react";
import { Image, Text, VStack } from "@chakra-ui/react";
import rocket from "../assets/images/rocket.png";

const Instruction = () => {
  return (
    <VStack
      minH={"calc(100vh - 85px - 70px)"}
      align={"center"}
      justify={"center"}
      spacing={4}
    >
      <Image src={rocket} objectFit={"initial"} w={"44"} h={"44"} />
      <Text fontWeight={"medium"} fontSize={18} color={"#000"}>
        Тун удахгүй
      </Text>
    </VStack>
  );
};

export default Instruction;
