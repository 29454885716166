import React, { useState, useEffect, useContext } from "react";
import {
  HStack,
  Image,
  Text,
  VStack,
  Box,
  Icon,
  Button,
  IconButton,
  Spinner,
  Tooltip,
  Center,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { TbArrowBackUp } from "react-icons/tb";
import axios from "../axios";
import UserContext from "../context/UserContext";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { RiErrorWarningLine } from "react-icons/ri";

const ProductDetail = () => {
  const [data, setData] = useState({});
  const history = useHistory();
  const [deviceId, setDeviceId] = useState(history.location.state._id);
  const [products, setProducts] = useState(history.location.state.products);
  const allProducts = history.location.state.products;
  const [carouselPageItems, setCarouselPageItems] = useState();
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [carouselScrollWidth, setCarouselScrollWidth] = useState(0);
  const [carouselSpacing, setCarouselSpacing] = useState(4);
  const [scrolling, setScrolling] = useState(false);
  const [carouselItemWidth, setCarouselItemWidth] = useState(0);
  const { isLoading, setIsLoading } = useContext(UserContext);
  const transitionDelays = [
    0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6,
    1.7, 1.8, 1.9, 2, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 3,
  ];

  useEffect(() => {
    let newProducts = allProducts.filter((p) => p._id !== deviceId);
    setProducts(newProducts);
  }, [deviceId]);

  const handlePrev = () => {
    if (scrolling) return;
    const carousel = document.querySelector(".carousel");
    if (carousel.scrollLeft === 0) return;
    setScrolling(true);
    carousel.scrollBy(carouselScrollWidth * -1, 0);
    setTimeout(() => {
      setScrolling(false);
    }, 600);
  };

  const handleNext = () => {
    if (scrolling) return;
    const carousel = document.querySelector(".carousel");
    setScrolling(true);
    carousel.scrollBy(carouselScrollWidth, 0);
    setTimeout(() => {
      setScrolling(false);
    }, 600);
  };

  const calculateCarouselDimensions = () => {
    if (window.innerWidth < 480) {
      setCarouselPageItems(1);
      setCarouselSpacing(0);
      setCarouselItemWidth(208);
    } else if (window.innerWidth < 964) {
      setCarouselPageItems(2);
      setCarouselItemWidth(208);
    } else if (window.innerWidth < 1324) {
      setCarouselPageItems(3);
      setCarouselItemWidth(208);
    } else {
      setCarouselPageItems(4);
      setCarouselItemWidth(272);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/landin/get_device", {
        params: {
          _id: deviceId,
        },
      })
      .then((result) => {
        if (result.data.success) {
          setData(result.data.data);
          // console.log(result.data.data.payload);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  }, [deviceId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    calculateCarouselDimensions();

    window.addEventListener("resize", () => {
      calculateCarouselDimensions();
    });
  }, []);

  useEffect(() => {
    if (carouselItemWidth) {
      // NOTE:
      // * 4 oor urjuuldegiin uchir n 1 spacing value 4px tei tentsuu
      // * 1 iig hasaj baigaa n carousel items uudiin hoorondoh zai
      if (carouselPageItems === 4) {
        setCarouselWidth(
          carouselItemWidth * 4 + carouselSpacing * 4 * (carouselPageItems - 1)
        );
        setCarouselScrollWidth(
          carouselItemWidth * 4 + carouselSpacing * 4 * carouselPageItems
        );
        return;
      }

      if (carouselPageItems === 3) {
        setCarouselWidth(
          carouselItemWidth * 3 + carouselSpacing * 4 * (carouselPageItems - 1)
        );
        setCarouselScrollWidth(
          carouselItemWidth * 3 + carouselSpacing * 4 * carouselPageItems
        );
        return;
      }

      if (carouselPageItems === 2) {
        setCarouselWidth(
          carouselItemWidth * 2 + carouselSpacing * 4 * (carouselPageItems - 1)
        );
        setCarouselScrollWidth(
          carouselItemWidth * 2 + carouselSpacing * 4 * carouselPageItems
        );
        return;
      }
      if (carouselPageItems === 1) {
        setCarouselWidth(
          carouselItemWidth - carouselSpacing * 4 * (carouselPageItems - 1)
        );
        setCarouselScrollWidth(carouselItemWidth);
      }
    }
  }, [carouselPageItems, carouselItemWidth]);

  useEffect(() => {
    const specs = document.querySelectorAll(".spec");

    specs.forEach((spec, index) => {
      if (index % 2 === 0) {
        spec.style.backgroundColor = "#fbfbfb";
      }
    });
  }, [data]);

  return (
    <VStack
      pt={10}
      scrollBehavior={"smooth"}
      w="full"
      pb={16}
      minH="calc(100vh - 85px - 70px)"
      spacing={0}
    >
      {/* TODO:   PRODUCT DETAIL (data)*/}
      {isLoading ? (
        <Center w="full" minH="calc(100vh - 260px)">
          <Spinner w={8} h={8} color="#45A735" />
        </Center>
      ) : (
        <>
          {Object.keys(data).length > 0 ? (
            <HStack
              maxW="full"
              justify={{ lg: "center" }}
              p={8}
              pb={20}
              spacing={{ lg: 20, xl: 28 }}
              rowGap={10}
              minH="calc(100vh - 260px)"
              flexDirection={{ base: "column", lg: "row" }}
            >
              <Box
                pos={"relative"}
                rounded={{ base: "md", sm: "3xl" }}
                bg="#fbfbfb"
                py={{ base: 5, md: 10, xl: 16 }}
                px={14}
                as={motion.div}
                variants={{
                  offscreen: {
                    opacity: 0,
                    y: 400,
                  },
                  onscreen: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      type: "spring",
                      duration: 2,
                    },
                  },
                }}
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true }}
              >
                <Image
                  src={data.imgUrl}
                  objectFit={"contain"}
                  w={{ base: "32", sm: "52", md: "72", lg: "80" }}
                  h={{ base: "32", sm: "52", md: "72", lg: 80 }}
                />
                <IconButton
                  h={{ base: 8, md: 9, lg: 10 }}
                  w={{ base: 8, md: 9, lg: 10 }}
                  cursor="pointer"
                  pos="absolute"
                  top={-14}
                  left="0"
                  transition={"ease-in-out .3s"}
                  bg="#fbfbfb"
                  rounded={"lg"}
                  p={0}
                  _hover={{
                    bg: "#fff",
                    shadow: "md",
                  }}
                  icon={
                    <Icon
                      as={TbArrowBackUp}
                      w={{ base: 4, md: 5 }}
                      h={{ base: 4, md: 5 }}
                    />
                  }
                  _focus={{
                    outline: "none",
                    bg: "#fff",
                  }}
                  onClick={() => history.goBack()}
                  as={motion.div}
                  variants={{
                    offscreen: {
                      opacity: 0,
                    },
                    onscreen: {
                      opacity: 1,
                      transition: {
                        type: "spring",
                        duration: 3,
                      },
                    },
                  }}
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={{ once: true }}
                />
              </Box>
              <VStack
                maxW={"fit-content"}
                w="full"
                alignItems="start"
                spacing={8}
              >
                <VStack
                  fontSize={{ base: 16, xl: 17 }}
                  alignItems={"start"}
                  spacing={{ base: 0, xl: 1 }}
                >
                  <Text
                    fontSize={{ base: 13, xl: 15 }}
                    color="#45A735"
                    as={motion.p}
                    variants={{
                      offscreen: {
                        opacity: 0,
                        x: "100%",
                      },
                      onscreen: {
                        opacity: 1,
                        x: 0,
                        transition: {
                          type: "spring",
                          duration: 1,
                          delay: transitionDelays.shift(),
                        },
                      },
                    }}
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{ once: true }}
                  >
                    {data.category.map((c, index) => (
                      <React.Fragment key={index}>
                        {c}
                        {index !== data.category.length - 1 && ", "}
                      </React.Fragment>
                    ))}
                  </Text>
                  <Text
                    fontSize={{ base: 15, md: 19, xl: 20 }}
                    fontWeight={"medium"}
                    as={motion.p}
                    variants={{
                      offscreen: {
                        opacity: 0,
                        x: "100%",
                      },
                      onscreen: {
                        opacity: 1,
                        x: 0,
                        transition: {
                          type: "spring",
                          duration: 1,
                          delay: transitionDelays.shift(),
                        },
                      },
                    }}
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{ once: true }}
                  >
                    {data.deviceName}
                  </Text>
                  <VStack pt={4}>
                    <Text
                      py={{ base: 1, xl: 1.5 }}
                      px={{ base: 6, xl: 8 }}
                      border={"1px solid #000"}
                      as={motion.p}
                      variants={{
                        offscreen: {
                          opacity: 0,
                          x: "100%",
                        },
                        onscreen: {
                          opacity: 1,
                          x: 0,
                          transition: {
                            type: "spring",
                            duration: 1,
                            delay: transitionDelays.shift(),
                          },
                        },
                      }}
                      initial={"offscreen"}
                      whileInView={"onscreen"}
                      viewport={{ once: true }}
                    >
                      {`₮ ${data.price.toLocaleString("en-US")}`}
                    </Text>
                  </VStack>
                </VStack>
                <VStack
                  fontSize={{ base: 12, xl: 13 }}
                  spacing={0}
                  w={{
                    base: "full",
                    sm: "sm",
                  }}
                >
                  <HStack
                    className="spec"
                    px={8}
                    py={2}
                    rounded="lg"
                    w="full"
                    justify="space-between"
                    as={motion.div}
                    variants={{
                      offscreen: {
                        opacity: 0,
                        x: "100%",
                      },
                      onscreen: {
                        opacity: 1,
                        x: 0,
                        transition: {
                          type: "spring",
                          duration: 0.7,
                          delay: transitionDelays.shift(),
                        },
                      },
                    }}
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{ once: true }}
                  >
                    <Text>Хэмжээ :</Text>
                    <Text>{data.size}</Text>
                  </HStack>

                  <HStack
                    className="spec"
                    px={8}
                    py={2}
                    rounded="lg"
                    w="full"
                    justify="space-between"
                    as={motion.div}
                    variants={{
                      offscreen: {
                        opacity: 0,
                        x: "100%",
                      },
                      onscreen: {
                        opacity: 1,
                        x: 0,
                        transition: {
                          type: "spring",
                          duration: 1,
                          delay: transitionDelays.shift(),
                        },
                      },
                    }}
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{ once: true }}
                  >
                    <Text>Холболт :</Text>
                    <Text>{data.connection}</Text>
                  </HStack>

                  <HStack
                    className="spec"
                    px={8}
                    py={2}
                    rounded="lg"
                    w="full"
                    justify="space-between"
                    as={motion.div}
                    variants={{
                      offscreen: {
                        opacity: 0,
                        x: "100%",
                      },
                      onscreen: {
                        opacity: 1,
                        x: 0,
                        transition: {
                          type: "spring",
                          duration: 1,
                          delay: transitionDelays.shift(),
                        },
                      },
                    }}
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{ once: true }}
                  >
                    <Text>Оролт :</Text>
                    <Text>{data.in}</Text>
                  </HStack>

                  {/* -------------  TODO:    payload    ------------  */}
                  {data.payload ? (
                    <>
                      {data.payload.out ? (
                        <HStack
                          className="spec"
                          px={8}
                          py={2}
                          rounded="lg"
                          w="full"
                          justify="space-between"
                          as={motion.div}
                          variants={{
                            offscreen: {
                              opacity: 0,
                              x: "100%",
                            },
                            onscreen: {
                              opacity: 1,
                              x: 0,
                              transition: {
                                type: "spring",
                                duration: 1,
                                delay: transitionDelays.shift(),
                              },
                            },
                          }}
                          initial={"offscreen"}
                          whileInView={"onscreen"}
                          viewport={{ once: true }}
                        >
                          <Text>Гүйдэл :</Text>
                          <Text>{data.payload.out}</Text>
                        </HStack>
                      ) : null}

                      {data.payload.temperature ? (
                        <HStack
                          className="spec"
                          px={8}
                          py={2}
                          rounded="lg"
                          w="full"
                          justify="space-between"
                          as={motion.div}
                          variants={{
                            offscreen: {
                              opacity: 0,
                              x: "100%",
                            },
                            onscreen: {
                              opacity: 1,
                              x: 0,
                              transition: {
                                type: "spring",
                                duration: 1,
                                delay: transitionDelays.shift(),
                              },
                            },
                          }}
                          initial={"offscreen"}
                          whileInView={"onscreen"}
                          viewport={{ once: true }}
                        >
                          <Text>Ажиллах температур :</Text>
                          <Text> {data.payload.temperature}</Text>
                        </HStack>
                      ) : null}

                      {data.payload.humidity ? (
                        <HStack
                          className="spec"
                          px={8}
                          py={2}
                          rounded="lg"
                          w="full"
                          justify="space-between"
                          as={motion.div}
                          variants={{
                            offscreen: {
                              opacity: 0,
                              x: "100%",
                            },
                            onscreen: {
                              opacity: 1,
                              x: 0,
                              transition: {
                                type: "spring",
                                duration: 1,
                                delay: transitionDelays.shift(),
                              },
                            },
                          }}
                          initial={"offscreen"}
                          whileInView={"onscreen"}
                          viewport={{ once: true }}
                        >
                          <Text>Ажиллах чийгшил :</Text>
                          <Text>{data.payload.humidity}</Text>
                        </HStack>
                      ) : null}

                      {data.payload.noiseAlarm ? (
                        <HStack
                          className="spec"
                          px={8}
                          py={2}
                          rounded="lg"
                          w="full"
                          justify="space-between"
                          as={motion.div}
                          variants={{
                            offscreen: {
                              opacity: 0,
                              x: "100%",
                            },
                            onscreen: {
                              opacity: 1,
                              x: 0,
                              transition: {
                                type: "spring",
                                duration: 1,
                                delay: transitionDelays.shift(),
                              },
                            },
                          }}
                          initial={"offscreen"}
                          whileInView={"onscreen"}
                          viewport={{ once: true }}
                        >
                          <Text>Дуут дохио :</Text>
                          <Text>{data.payload.noiseAlarm}</Text>
                        </HStack>
                      ) : null}

                      {data.payload.resistantTo ? (
                        <HStack
                          className="spec"
                          px={8}
                          py={2}
                          rounded="lg"
                          w="full"
                          justify="space-between"
                          as={motion.div}
                          variants={{
                            offscreen: {
                              opacity: 0,
                              x: "100%",
                            },
                            onscreen: {
                              opacity: 1,
                              x: 0,
                              transition: {
                                type: "spring",
                                duration: 1,
                                delay: transitionDelays.shift(),
                              },
                            },
                          }}
                          initial={"offscreen"}
                          whileInView={"onscreen"}
                          viewport={{ once: true }}
                        >
                          <Text>Хамгаалалт :</Text>
                          <Text>{data.payload.resistantTo}</Text>
                        </HStack>
                      ) : null}

                      {data.payload.smokeSensingArea ? (
                        <HStack
                          className="spec"
                          px={8}
                          py={2}
                          rounded="lg"
                          w="full"
                          justify="space-between"
                          as={motion.div}
                          variants={{
                            offscreen: {
                              opacity: 0,
                              x: "100%",
                            },
                            onscreen: {
                              opacity: 1,
                              x: 0,
                              transition: {
                                type: "spring",
                                duration: 1,
                                delay: transitionDelays.shift(),
                              },
                            },
                          }}
                          initial={"offscreen"}
                          whileInView={"onscreen"}
                          viewport={{ once: true }}
                        >
                          <Text>Утаа мэдрэх талбай :</Text>
                          <Text>{data.payload.smokeSensingArea}</Text>
                        </HStack>
                      ) : null}

                      {data.payload.infraredDegree ? (
                        <HStack
                          className="spec"
                          px={8}
                          py={2}
                          rounded="lg"
                          w="full"
                          justify="space-between"
                          as={motion.div}
                          variants={{
                            offscreen: {
                              opacity: 0,
                              x: "100%",
                            },
                            onscreen: {
                              opacity: 1,
                              x: 0,
                              transition: {
                                type: "spring",
                                duration: 1,
                                delay: transitionDelays.shift(),
                              },
                            },
                          }}
                          initial={"offscreen"}
                          whileInView={"onscreen"}
                          viewport={{ once: true }}
                        >
                          <Text>Долгион дамжуулах өнцөг :</Text>
                          <Text>{data.payload.infraredDegree}</Text>
                        </HStack>
                      ) : null}

                      {data.payload.lux ? (
                        <HStack
                          className="spec"
                          px={8}
                          py={2}
                          rounded="lg"
                          w="full"
                          justify="space-between"
                          as={motion.div}
                          variants={{
                            offscreen: {
                              opacity: 0,
                              x: "100%",
                            },
                            onscreen: {
                              opacity: 1,
                              x: 0,
                              transition: {
                                type: "spring",
                                duration: 1,
                                delay: transitionDelays.shift(),
                              },
                            },
                          }}
                          initial={"offscreen"}
                          whileInView={"onscreen"}
                          viewport={{ once: true }}
                        >
                          <Text>Гэрэл хэмжих :</Text>
                          <Text>{data.payload.lux}</Text>
                        </HStack>
                      ) : null}

                      {data.payload.sensingRange ? (
                        <HStack
                          className="spec"
                          px={8}
                          py={2}
                          rounded="lg"
                          w="full"
                          justify="space-between"
                          as={motion.div}
                          variants={{
                            offscreen: {
                              opacity: 0,
                              x: "100%",
                            },
                            onscreen: {
                              opacity: 1,
                              x: 0,
                              transition: {
                                type: "spring",
                                duration: 1,
                                delay: transitionDelays.shift(),
                              },
                            },
                          }}
                          initial={"offscreen"}
                          whileInView={"onscreen"}
                          viewport={{ once: true }}
                        >
                          <Text>Мэдрэх хүрээ :</Text>
                          <Text>{data.payload.sensingRange}</Text>
                        </HStack>
                      ) : null}

                      {data.payload.sensingDegree ? (
                        <HStack
                          className="spec"
                          px={8}
                          py={2}
                          rounded="lg"
                          w="full"
                          justify="space-between"
                          as={motion.div}
                          variants={{
                            offscreen: {
                              opacity: 0,
                              x: "100%",
                            },
                            onscreen: {
                              opacity: 1,
                              x: 0,
                              transition: {
                                type: "spring",
                                duration: 1,
                                delay: transitionDelays.shift(),
                              },
                            },
                          }}
                          initial={"offscreen"}
                          whileInView={"onscreen"}
                          viewport={{ once: true }}
                        >
                          <Text>Мэдрэх өнцөг :</Text>
                          <Text>{data.payload.sensingDegree}</Text>
                        </HStack>
                      ) : null}

                      {data.payload.electronTransference ? (
                        <HStack
                          className="spec"
                          px={8}
                          py={2}
                          rounded="lg"
                          w="full"
                          justify="space-between"
                          as={motion.div}
                          variants={{
                            offscreen: {
                              opacity: 0,
                              x: "100%",
                            },
                            onscreen: {
                              opacity: 1,
                              x: 0,
                              transition: {
                                type: "spring",
                                duration: 1,
                                delay: transitionDelays.shift(),
                              },
                            },
                          }}
                          initial={"offscreen"}
                          whileInView={"onscreen"}
                          viewport={{ once: true }}
                        >
                          <Text>Электрон дамжуулах :</Text>
                          <Text>{data.payload.electronTransference}</Text>
                        </HStack>
                      ) : null}

                      {data.payload.co ? (
                        <HStack
                          className="spec"
                          px={8}
                          py={2}
                          rounded="lg"
                          w="full"
                          justify="space-between"
                          as={motion.div}
                          variants={{
                            offscreen: {
                              opacity: 0,
                              x: "100%",
                            },
                            onscreen: {
                              opacity: 1,
                              x: 0,
                              transition: {
                                type: "spring",
                                duration: 1,
                                delay: transitionDelays.shift(),
                              },
                            },
                          }}
                          initial={"offscreen"}
                          whileInView={"onscreen"}
                          viewport={{ once: true }}
                        >
                          <Text>Нүүрстөрөгчийн давхар исэл :</Text>
                          <Text>{data.payload.co}</Text>
                        </HStack>
                      ) : null}

                      {data.payload.VOC ? (
                        <HStack
                          className="spec"
                          px={8}
                          py={2}
                          rounded="lg"
                          w="full"
                          justify="space-between"
                          as={motion.div}
                          variants={{
                            offscreen: {
                              opacity: 0,
                              x: "100%",
                            },
                            onscreen: {
                              opacity: 1,
                              x: 0,
                              transition: {
                                type: "spring",
                                duration: 1,
                                delay: transitionDelays.shift(),
                              },
                            },
                          }}
                          initial={"offscreen"}
                          whileInView={"onscreen"}
                          viewport={{ once: true }}
                        >
                          <Text>Нүүрстөрөгчийн дутуу исэл :</Text>
                          <Text>{data.payload.VOC}</Text>
                        </HStack>
                      ) : null}

                      {data.payload.formaldehyde ? (
                        <HStack
                          className="spec"
                          px={8}
                          py={2}
                          rounded="lg"
                          w="full"
                          justify="space-between"
                          as={motion.div}
                          variants={{
                            offscreen: {
                              opacity: 0,
                              x: "100%",
                            },
                            onscreen: {
                              opacity: 1,
                              x: 0,
                              transition: {
                                type: "spring",
                                duration: 1,
                                delay: transitionDelays.shift(),
                              },
                            },
                          }}
                          initial={"offscreen"}
                          whileInView={"onscreen"}
                          viewport={{ once: true }}
                        >
                          <Text>Формальдегид :</Text>
                          <Text>{data.payload.formaldehyde}</Text>
                        </HStack>
                      ) : null}
                    </>
                  ) : null}

                  {/*  ----------------- TODO:    payload  END   -------------- */}
                </VStack>
                <Text
                  pt={2}
                  w={{ base: "2xs", sm: "sm", md: "md", xl: "xl" }}
                  fontSize={{ base: 13, sm: 14, xl: 15 }}
                  wordBreak={"break-word"}
                  as={motion.p}
                  variants={{
                    offscreen: {
                      opacity: 0,
                      x: "100%",
                    },
                    onscreen: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        type: "spring",
                        duration: 1,
                        delay: transitionDelays.shift(),
                      },
                    },
                  }}
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={{ once: true }}
                >
                  {data.description}
                </Text>
              </VStack>
            </HStack>
          ) : (
            <VStack
              w="full"
              minH={"calc(100vh - 200px)"}
              align={"center"}
              justify={"center"}
            >
              <Icon color={"#ababab"} w={6} h={6} as={RiErrorWarningLine} />
              <Text fontSize={15} color={"#ababab"}>
                Мэдээлэл олдсонгүй
              </Text>
            </VStack>
          )}
        </>
      )}

      {/*  TODO:  CAROUSEL     */}
      {products.length > 0 ? (
        <VStack
          as={motion.div}
          variants={{
            offscreen: {
              opacity: 0,
              scale: 0.5,
            },
            onscreen: {
              opacity: 1,
              scale: 1,
              transition: {
                type: "spring",
                duration: 1.5,
              },
            },
          }}
          initial="offscreen"
          whileInView={"onscreen"}
          viewport={{ once: true }}
        >
          <HStack
            className="carousel"
            minW={carouselWidth}
            w={carouselWidth}
            maxW={carouselWidth}
            mx="auto"
            overflowX={"auto"}
            scrollBehavior={"smooth"}
            spacing={carouselSpacing}
            css={{
              scrollbarWidth: "none",
              "-ms-overflow-style": "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {products.map((e, index) => (
              <Box
                key={index}
                className="carousel-item"
                minW={`${carouselItemWidth}px`}
                w={`${carouselItemWidth}px`}
                maxW={`${carouselItemWidth}px`}
                pb="1"
              >
                <VStack
                  minw="fit-content"
                  w="fit-content"
                  maxW="fit-content"
                  boxShadow="0 3px 4px -2px rgba(0, 0, 0, 0.1)"
                  overflow={"hidden"}
                  rounded={"md"}
                  spacing={4}
                  pb={4}
                >
                  <VStack
                    bg="#fbfbfb"
                    spacing={3}
                    py={{ base: 4, sm: 5 }}
                    px={{ base: 5, sm: 10 }}
                  >
                    <Text fontSize={{ base: 12, xl: 13 }} color={"#45A735"}>
                      {e.category.map((c, index) => (
                        <React.Fragment key={index}>
                          {c}
                          {index !== e.category.length - 1 && ", "}
                        </React.Fragment>
                      ))}
                    </Text>
                    <Image
                      src={e.imgUrl}
                      objectFit={"contain"}
                      minW={{
                        base: "32",
                        md: "32",
                        lg: "32",
                        xl: "48",
                      }}
                      minH={{
                        base: "32",
                        md: "32",
                        lg: "32",
                        xl: "48",
                      }}
                      maxW={{
                        base: "32",
                        md: "32",
                        lg: "32",
                        xl: "48",
                      }}
                      maxH={{
                        base: "32",
                        md: "32",
                        lg: "32",
                        xl: "48",
                      }}
                      transition="0.2s ease-in-out"
                      _hover={{
                        transform: "scale(1.1)",
                      }}
                    />
                  </VStack>
                  <VStack spacing={3}>
                    <Text
                      px={2}
                      textAlign={"center"}
                      fontSize={{ base: 13, xl: 16 }}
                      fontWeight={"medium"}
                      overflow={"hidden"}
                      display={"-webkit-box"}
                      sx={{
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                      }}
                    >
                      {e.deviceName}
                    </Text>

                    <Text
                      fontSize={{ base: 10, xl: 12 }}
                      py={{ base: 0.5, xl: 1 }}
                      px={{ base: 4, xl: 5 }}
                      rounded={"md"}
                      border={"1px solid #a6a6a6"}
                      cursor={"pointer"}
                      transition={"0.3s linear"}
                      _hover={{
                        color: "#fff",
                        bg: "#45A735",
                        borderColor: "transparent",
                      }}
                      onClick={() => {
                        setDeviceId(e._id);
                        window.scrollTo(0, 0);
                      }}
                    >
                      Дэлгэрэнгүй
                    </Text>
                  </VStack>
                </VStack>
              </Box>
            ))}
          </HStack>
          {products.length > carouselPageItems ? (
            <HStack w="full" justify={"space-between"} mt="4">
              <Button
                fontSize={"10"}
                fontWeight={"normal"}
                color="#45A735"
                size={{ base: "xs", lg: "sm" }}
                onClick={handlePrev}
                bg="#f3f3f3"
                _hover={{
                  bg: "#e8e6e6",
                }}
                display="flex"
                alignItems="center"
              >
                <Icon as={MdKeyboardArrowLeft} />

                {carouselPageItems > 2 ? "өмнөх" : null}
              </Button>
              <Button
                fontSize={"10"}
                fontWeight={"normal"}
                color="#45A735"
                size={{ base: "xs", lg: "sm" }}
                onClick={handleNext}
                bg="#f3f3f3"
                _hover={{
                  bg: "#e8e6e6",
                }}
              >
                {carouselPageItems > 2 ? "дараагийн" : null}
                <Icon as={MdKeyboardArrowRight} />
              </Button>
            </HStack>
          ) : null}
        </VStack>
      ) : null}
    </VStack>
  );
};

export default ProductDetail;
