import React, { createContext, useState } from "react";
const UserContext = createContext();

export const UserStore = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <UserContext.Provider value={{ isLoading, setIsLoading }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
