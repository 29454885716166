import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  HStack,
  Stack,
  Image,
  Center,
  Icon,
  Text,
  Flex,
  Link,
  Box,
} from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import { FiMail, FiMapPin, FiPhone } from "react-icons/fi";
import { navData } from "./NavbarData";
import logo from "../../assets/images/logo.png";
import { IoIosArrowForward } from "react-icons/io";

const NavbarMobile = ({ showSideBar, toggleSidebar }) => {
  const location = useLocation();

  return (
    <Drawer
      onClose={toggleSidebar}
      isOpen={showSideBar}
      size={"xs"}
      placement={"left"}
    >
      <DrawerOverlay />
      <DrawerContent w={"full"}>
        <DrawerCloseButton _focus={{ outline: "none", shadow: "none" }} />
        <DrawerHeader>
          <Image src={logo} objectFit="contain" w={"36"} />
        </DrawerHeader>
        <DrawerBody w={"full"} p={"0"}>
          <VStack
            align={"center"}
            justify={"space-between"}
            w={"full"}
            h={"full"}
          >
            <Stack pb={"8"} w={"80%"} align={"flex-start"}>
              {navData.map((e, index) => (
                <Flex
                  key={index}
                  cursor="pointer"
                  width={"full"}
                  color={"#F7F7F7"}
                  as={NavLink}
                  to={e.toLink}
                  onClick={toggleSidebar}
                  style={{
                    textDecoration: "none",
                    alignSelf: "center",
                    width: "100%",
                    padding: 8,
                  }}
                  _hover={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: 10,
                  }}
                >
                  <HStack align="center" cursor="pointer">
                    <Box
                      w={"1.5"}
                      h={"1.5"}
                      rounded={"full"}
                      bg={
                        location.pathname.split("/")[1] ===
                        e.toLink.split("/")[1]
                          ? "#45A735"
                          : "#909090"
                      }
                    />
                    <Text
                      w={"full"}
                      color={
                        location.pathname.split("/")[1] ===
                        e.toLink.split("/")[1]
                          ? "#000"
                          : "#5D5D5D"
                      }
                      fontSize={"sm"}
                      fontWeight={
                        location.pathname.split("/")[1] ===
                        e.toLink.split("/")[1]
                          ? "medium"
                          : "normal"
                      }
                      ml={"2"}
                    >
                      {e.title}
                    </Text>
                  </HStack>
                </Flex>
              ))}

              <Link href="https://web.viot.mn/" isExternal>
                <Center
                  borderWidth={"thin"}
                  borderColor={"#45A735"}
                  rounded={"md"}
                  w={"40"}
                  py={"2"}
                  // px={"6"}
                  // transition={"ease-in-out .5s"}
                >
                  <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
                    Бүртгүүлэх
                  </Text>
                  <Icon
                    as={IoIosArrowForward}
                    ml={"2"}
                    w={"4"}
                    h={"4"}
                    // display={showArrow ? "block" : "none"}
                  />
                </Center>
              </Link>
            </Stack>

            <VStack align={"center"} justify={"center"} spacing={4} w={"full"}>
              <HStack w={"80%"} align={"flex-start"}>
                <Icon as={FiPhone} color={"#000"} w={5} h={5} />
                <Link href="tel:+97672722072">
                  <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
                    Утас: +976 72722072
                  </Text>
                </Link>
              </HStack>
              <HStack w={"80%"} align={"flex-start"}>
                <Icon as={FiMail} color={"#000"} w={5} h={5} />
                <Link href="mailto:info@viot.mn">
                  <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
                    И-мэйл хаяг: info@viot.mn
                  </Text>
                </Link>
              </HStack>
              <HStack w={"80%"} align={"flex-start"}>
                <Icon as={FiMapPin} color={"#000"} w={5} h={5} />
                <Link
                  href="https://what3words.com/%D0%B4%D1%83%D1%80%D1%81%D0%B0%D0%BC%D0%B6.%D0%B3%D0%BE%D0%BB%D0%BB%D0%BE%D0%BD%D0%BE.%D0%B4%D3%A9%D1%85%D0%BD%D3%A9"
                  isExternal
                >
                  <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
                    Хаяг : ///дурсамж.голлоно.дөхнө
                  </Text>
                </Link>
              </HStack>
              <Center bg={"#F9F9F9"} py={"4"} w={"full"}>
                <Text color={"#000"} fontSize={13} fontWeight={"normal"}>
                  Developed by{" "}
                  <Text
                    as="span"
                    color={"#000"}
                    fontSize={13}
                    fontWeight={"medium"}
                    textTransform={"uppercase"}
                  >
                    nuden solution llc
                  </Text>
                </Text>
              </Center>
            </VStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default NavbarMobile;
