import React, { useState, useEffect, useContext } from "react";
import {
  Image,
  Text,
  VStack,
  SimpleGrid,
  Spinner,
  Tooltip,
  Center,
  Icon,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import axios from "../axios";
import UserContext from "../context/UserContext";
import { RiErrorWarningLine } from "react-icons/ri";

const Product = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(8); // The number of items to display per page
  const pageCount = Math.ceil(data.length / perPage);
  const startIndex = currentPage * perPage;
  const endIndex = startIndex + perPage;
  const pageData = data.slice(startIndex, endIndex);
  const history = useHistory();
  const { isLoading, setIsLoading } = useContext(UserContext);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/landin/cards")
      .then((result) => {
        if (result.data.success) {
          // console.log(result.data);
          setData(result.data.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 992) {
        setPerPage(6);
      } else {
        setPerPage(8);
      }
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <Center w="full" h={"calc(100vh - 85px - 40px)"}>
          <Spinner w={8} h={8} color="#45A735" />
        </Center>
      ) : (
        <>
          {pageData.length > 0 ? (
            <VStack
              w="full"
              minH={"calc(100vh - 85px - 40px)"}
              align={"center"}
              justify={"center"}
              spacing={4}
              pt={"12"}
              pb={{ base: 5, md: 0 }}
              justifyContent={"space-between"}
            >
              <SimpleGrid
                h="full"
                rowGap={5}
                columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
                columnGap={6}
              >
                {/* {pageData.length > 0 ? ( */}
                {pageData.map((e, index) => (
                  <VStack
                    w="min-content"
                    key={index}
                    boxShadow="0 3px 4px -2px rgba(0, 0, 0, 0.1)"
                    overflow={"hidden"}
                    rounded={"md"}
                    spacing={4}
                    pb={4}
                    as={motion.div}
                    variants={{
                      offscreen: {
                        opacity: 0,
                        y: [0, 1, 2, 3].includes(index) ? -100 : 100,
                      },
                      onscreen: {
                        opacity: 1,
                        y: 0,
                        transition: {
                          type: "spring",
                          duration: 0.6,
                          delay: index * 0.1,
                        },
                      },
                    }}
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{ once: true }}
                  >
                    <VStack bg="#fbfbfb" spacing={3} py={5} px={10}>
                      <Text
                        fontWeight={"medium"}
                        fontSize={{ base: 13, xl: 14 }}
                        color={"#45A735"}
                      >
                        {e.category.map((c, index) => (
                          <React.Fragment key={index}>
                            {c}
                            {index !== e.category.length - 1 && ", "}
                          </React.Fragment>
                        ))}
                      </Text>
                      <Image
                        src={e.imgUrl}
                        objectFit={"contain"}
                        minW={{ base: "32", md: "32", lg: "32", xl: "52" }}
                        minH={{ base: "32", md: "32", lg: "32", xl: "52" }}
                        maxW={{ base: "32", md: "32", lg: "32", xl: "52" }}
                        maxH={{ base: "32", md: "32", lg: "32", xl: "52" }}
                        transition="0.2s ease-in-out"
                        _hover={{
                          transform: "scale(1.1)",
                        }}
                      />
                    </VStack>
                    <VStack spacing={3}>
                      <Text
                        px={4}
                        fontSize={{ base: 15, xl: 16 }}
                        fontWeight={"medium"}
                        textAlign={"center"}
                        overflow={"hidden"}
                        display={"-webkit-box"}
                        sx={{
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                        }}
                      >
                        {e.deviceName}
                      </Text>

                      <Text
                        fontSize={{ base: 11, xl: 12 }}
                        py={{ base: 0.5, xl: 1 }}
                        px={{ base: 4, xl: 5 }}
                        rounded={"md"}
                        border={"1px solid #a6a6a6"}
                        cursor={"pointer"}
                        onClick={() =>
                          history.push("/product-detail", {
                            _id: e._id,
                            products: data,
                          })
                        }
                        transition={"0.3s linear"}
                        _hover={{
                          bg: "#45A735",
                          color: "#fff",
                          borderColor: "transparent",
                        }}
                      >
                        Дэлгэрэнгүй
                      </Text>
                    </VStack>
                  </VStack>
                ))}
              </SimpleGrid>

              {data.length > perPage ? (
                <ReactPaginate
                  previousLabel="<"
                  nextLabel=">"
                  pageCount={pageCount}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={({ selected }) => setCurrentPage(selected)}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousLinkClassName="page-num"
                  nextLinkClassName="page-num"
                  activeLinkClassName="active"
                  onClick={() => {
                    if (window.innerWidth < 768) {
                      window.scrollTo(0, 100);
                    }
                  }}
                />
              ) : null}
            </VStack>
          ) : (
            <VStack
              w="full"
              minH={"calc(100vh - 85px - 40px)"}
              align={"center"}
              justify={"center"}
            >
              <Icon color="#ababab" w={6} h={6} as={RiErrorWarningLine} />
              <Text fontSize={15} color="#ababab">
                Мэдээлэл олдсонгүй
              </Text>
            </VStack>
          )}
        </>
      )}
    </>
  );
};

export default Product;
