import {
  Center,
  Container,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
  Grid,
  Box,
  Stack,
  Link,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import phone from "../assets/images/phone.png";
import model from "../assets/images/model.png";
import bg1 from "../assets/images/bg-01.svg";
import bubble from "../assets/images/bubble.png";
import bg3 from "../assets/images/bg-03.png";
import bubbleBlack from "../assets/images/bubbleBlack.png";
import logo3 from "../assets/icons/3logo.png";
import appstore from "../assets/icons/appstore.png";
import playstore from "../assets/icons/playstore.png";
import { motion } from "framer-motion";

const Home = () => {
  const [navShadow, setNavShadow] = useState(false);
  const scrollRef = useRef(null);

  const scrollHandler = () => {
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    // console.log("ref", scrollRef.current);
  };

  const changeShadow = () => {
    if (window.scrollY >= 80) {
      setNavShadow(true);
    } else {
      setNavShadow(false);
    }
  };

  window.addEventListener("scroll", changeShadow);

  const boxVariant = {
    visible: { opacity: 1, scale: 1.2, transition: { duration: 1 } },
    hidden: { opacity: 0, scale: 1 },
  };

  return (
    <VStack
      w={"full"}
      spacing={0}
      // overflowY={"hidden"}
      // css={{
      //   "&::-webkit-scrollbar": {
      //     width: "4px",
      //     height: "4px",
      //   },
      //   "&::-webkit-scrollbar-track": {
      //     width: "6px",
      //   },
      //   "&::-webkit-scrollbar-thumb": {
      //     background: "#45A735",
      //     borderRadius: "24px",
      //   },
      // }}
    >
      {/* ------------ mobile Banner ------------- */}
      <VStack
        display={{ base: "flex", md: "flex", lg: "none" }}
        pt={"10"}
        spacing={12}
        w={"full"}
      >
        <VStack
          w={{ base: "xs", md: "md" }}
          align={"center"}
          justify={"center"}
          spacing={3}
        >
          <Heading
            as="h3"
            size="lg"
            fontWeight={"bold"}
            color={"#000"}
            textTransform={"uppercase"}
          >
            <Text as={"span"} color={"#45A735"}>
              Ц
            </Text>
            огц систем
          </Heading>
          <Grid templateColumns={"repeat(2, 1fr)"} w={"full"} gap={2}>
            {system.map((e, index) => (
              <Stack
                key={index}
                w={"full"}
                spacing={2}
                direction={index % 2 === 0 ? "row" : "row-reverse"}
                alignItems={"center"}
                justifyContent={
                  index % 2 === 0 || index % 2 === 1 ? "flex-end" : "flex-start"
                }
              >
                <Text fontWeight={"medium"} color={"#000"} fontSize={16}>
                  {e.title}
                </Text>
                <Image src={e.icon} objectFit={"contain"} w={"7"} h={"7"} />
              </Stack>
            ))}
          </Grid>
        </VStack>
        <Image src={phone} objectFit={"contain"} w={"sm"} h={"md"} />
      </VStack>

      {/* ------------ Banner ------------- */}
      <VStack
        display={{ base: "none", md: " none", lg: "flex" }}
        // bg={"#fff"}
        w={"full"}
        h={{ base: "xs", md: "100vh" }}
        // py={"14"}
        pb={{ base: "0.5", md: "14" }}
        backgroundImage={{ base: bg1, lg: bg1 }}
        backgroundRepeat="no-repeat"
        backgroundSize={"cover"}
        backgroundPosition={"center"}
        position={"relative"}
      >
        <Center
          position={"absolute"}
          left={"-20"}
          bottom={0}
          as={motion.div}
          variants={{
            offscreen: {
              opacity: 0,
              y: 200,
            },
            onscreen: {
              opacity: 1,
              y: 0,
              transition: {
                // type: "easeInOut",
                // bounce: 0.4,
                duration: 0.8,
              },
            },
          }}
          initial="offscreen"
          animate="onscreen"
        >
          <Image
            src={bubble}
            objectFit={"contain"}
            w={{ base: "xs", md: "sm" }}
          />
        </Center>
        <Center
          position={"absolute"}
          right={40}
          top={5}
          as={motion.div}
          variants={{
            offscreen: {
              opacity: 0,
              x: 200,
            },
            onscreen: {
              opacity: 1,
              x: 0,
              transition: {
                // type: "easeInOut",
                // bounce: 0.4,
                duration: 0.8,
              },
            },
          }}
          initial="offscreen"
          animate="onscreen"
        >
          <Image
            src={bubble}
            objectFit={"contain"}
            w={{ base: "xs", md: "md" }}
          />
        </Center>
        <Container
          maxW={"container.xl"}
          w={{ base: "xs", md: "container.xl" }}
          h={"full"}
          pos={"relative"}
          centerContent
        >
          <HStack
            w={"full"}
            h={"full"}
            as={motion.div}
            variants={boxVariant}
            initial="hidden"
            animate="visible"
            align={"center"}
            justify={"center"}
            spacing={"32"}
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1 }}
            // transition={{ delay: 1.5, duration: 1.5 }}
          >
            <VStack
              maxW={{ base: "xs", md: "md" }}
              align={"flex-start"}
              spacing={3}
            >
              {system.map((e, index) => (
                <HStack spacing={4} key={index}>
                  <Image src={e.icon} objectFit={"contain"} w={"7"} h={"7"} />
                  <Text fontWeight={"medium"} color={"#000"} fontSize={16}>
                    {e.title}
                  </Text>
                </HStack>
              ))}

              <Heading
                as="h3"
                size="lg"
                fontWeight={"bold"}
                alignSelf={"flex-start"}
                color={"#000"}
                textTransform={"uppercase"}
              >
                <Text as={"span"} color={"#45A735"}>
                  Ц
                </Text>
                огц систем
              </Heading>
            </VStack>
            <Image
              src={model}
              objectFit={"contain"}
              w={"35vw"}
              display={{ base: "none", md: "flex" }}
            />
          </HStack>
          <Box
            className="arrow"
            display={{ base: "none", md: navShadow ? "none" : "block" }}
            cursor={"pointer"}
            onClick={scrollHandler}
          />
        </Container>
      </VStack>

      {/* ------------ Chance ------------- */}
      <VStack bg={"#fff"} w={"full"} py={"14"} ref={scrollRef}>
        <Container
          maxW={"container.xl"}
          w={{ base: "sm", md: "xl", lg: "container.xl" }}
          centerContent
        >
          <Image src={logo3} objectFit={"cover"} w={"36"} />
          <Heading
            as={"h3"}
            size={"lg"}
            fontWeight={"medium"}
            alignSelf={"flex-start"}
            mt={"10"}
          >
            Танд олгож буй боломжууд
          </Heading>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            columnGap={{ base: "0", md: "4", lg: "28" }}
            rowGap={"4"}
            mt={"8"}
          >
            {chance.map((e, index) => (
              <VStack
                key={index}
                align={"flex-start"}
                // maxW={"md"}
                w={"full"}
                spacing={4}
                p={{ base: "6", md: "4", lg: "8" }}
                borderRightWidth={"medium"}
                borderRightColor={"transparent"}
                transition={"ease-in-out .4s"}
                cursor={"default"}
                _hover={{
                  shadow: "2xl",
                  bg: "#fff",
                  rounded: "md",
                  borderRightWidth: "medium",
                  borderRightColor: "#45A735",
                }}
                as={motion.div}
                variants={{
                  offscreen: {
                    opacity: 0,
                    x: index % 2 === 0 ? -100 : 100,
                  },
                  onscreen: {
                    opacity: 1,
                    x: 0,
                    transition: {
                      type: "spring",
                      duration: 0.6,
                      delay: 0.4,
                    },
                  },
                }}
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true }}
              >
                <Center bg={"#ddd"} p={"4"} rounded={"md"}>
                  <Image src={e.img} objectFit={"contain"} w={"10"} h={"10"} />
                </Center>
                <Text color={"#000"} fontSize={16} fontWeight={"medium"}>
                  {e.title}
                </Text>
                <Text
                  color={"#000"}
                  fontSize={14}
                  fontWeight={"normal"}
                  textAlign={"justify"}
                >
                  {e.desc}
                </Text>
              </VStack>
            ))}
          </Grid>

          <Heading
            as="h3"
            size="lg"
            fontWeight={"medium"}
            alignSelf={"flex-start"}
            mt={"20"}
            mb={"4"}
          >
            Хаана ашиглах боломжтой вэ?
          </Heading>
          {where.map((e, index) => (
            <Stack
              align={"center"}
              justify={"center"}
              direction={{ base: "column", md: "column", lg: "row" }}
              key={index}
              w={"full"}
              mt={"6"}
              spacing={{ base: 4, md: 10 }}
              as={motion.div}
              variants={{
                offscreen: {
                  opacity: 0,
                  y: 200,
                },
                onscreen: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    // type: "easeInOut",
                    // bounce: 0.4,
                    duration: 0.8,
                    delay: index * 0.2,
                  },
                },
              }}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true }}
            >
              <Stack
                align={"center"}
                justify={"center"}
                direction={{ base: "column", md: "column", lg: "row" }}
                bg={"#F9F9F9"}
                w={"full"}
                // h={"40"}
                minH={"40"}
                py={"4"}
                px={{ base: "4", md: "14" }}
              >
                <HStack w={"full"} spacing={4}>
                  <Image
                    src={e.icon}
                    objectFit={"contain"}
                    w={{ base: "8", md: "12" }}
                    h={{ base: "8", md: "12" }}
                  />
                  <Text color={"#000"} fontSize={16} fontWeight={"medium"}>
                    {e.title}
                  </Text>
                </HStack>
                <Text
                  color={"#000"}
                  fontSize={14}
                  fontWeight={"normal"}
                  textAlign={"justify"}
                  maxW={"lg"}
                >
                  {e.desc}
                </Text>
              </Stack>
              <Image
                src={e.image}
                objectFit={"cover"}
                w={{ base: "full", md: "full", lg: "xs" }}
                h={"40"}
              />
            </Stack>
          ))}
        </Container>
      </VStack>

      {/* ------------ instruction ------------- */}
      <VStack bg={"#F9F9F9"} w={"full"} py={{ base: "0", md: "0", lg: "32" }}>
        <Stack
          w={{ base: "xs", md: "xl", lg: "container.xl" }}
          // bg={"red"}
          justify={"center"}
          align={{ md: "center", lg: "flex-end" }}
          pos={"relative"}
          mb={"8"}
        >
          <Image
            src={phone}
            objectFit={"contain"}
            w={"lg"}
            h={"xl"}
            pos={"absolute"}
            top={0}
            left={0}
            display={{ base: "none", md: "none", lg: "flex" }}
            as={motion.img}
            variants={{
              offscreen: {
                opacity: 0,
                y: 100,
              },
              onscreen: {
                opacity: 1,
                y: 0,
                transition: {
                  type: "easeInOut",
                  bounce: 0.4,
                  duration: 1,
                },
              },
            }}
            initial="offscreen"
            whileInView={"onscreen"}
            viewport={{ once: true }}
          />
          <VStack w={{ base: "xs", md: "lg" }} spacing={"6"}>
            <Heading
              as="h3"
              size="lg"
              fontWeight={"medium"}
              alignSelf={"flex-start"}
              mt={"10"}
            >
              VIOT Аппликейшн
            </Heading>
            <Text
              color={"#000"}
              fontSize={15}
              fontWeight={"normal"}
              textAlign={"justify"}
              maxW={{ base: "xs", md: "lg" }}
            >
              Та манай аппликейшн-ийг ашиглан өөрийн гэр, оффис, бизнесийн орчны
              тодорхой үзүүлэлтүүд болон цахилгаан тоноглолыг хянаж удирдан
              санаа зовох асуудлаа цөөлөөрэй.
            </Text>
            <Stack
              direction={{ base: "column", md: "row" }}
              w={"full"}
              justify={"space-between"}
              align={"center"}
            >
              <Link
                href="https://apps.apple.com/us/app/virtuoso-iot/id1617732575"
                w={"full"}
                _hover={{
                  textDecoration: "none",
                }}
                isExternal
              >
                <Center
                  borderWidth={"thin"}
                  border={"2px"}
                  borderColor={"#000"}
                  rounded={"2xl"}
                  w={"full"}
                  h={"16"}
                  cursor={"pointer"}
                  transition={"ease-out 2s"}
                  _hover={
                    {
                      // animation: StoreAnimation,
                      // animation: background
                      // background
                    }
                  }
                  as={motion.div}
                  // whileHover={{
                  //   bg: "#000",
                  // }}
                >
                  <HStack>
                    <Image
                      src={appstore}
                      objectFit={"contain"}
                      w={"12"}
                      h={"12"}
                    />
                    <VStack align={"flex-start"} spacing={0}>
                      <Text
                        color={"#000"}
                        fontSize={{ base: 12, md: 13 }}
                        fontWeight={"normal"}
                      >
                        Download on the
                      </Text>
                      <Text
                        color={"#000"}
                        fontSize={{ base: 24, md: 27 }}
                        fontWeight={"normal"}
                        lineHeight={"6"}
                      >
                        App Store
                      </Text>
                    </VStack>
                  </HStack>
                </Center>
              </Link>

              <Link
                href="https://play.google.com/store/apps/details?id=com.viot"
                w={"full"}
                _hover={{
                  textDecoration: "none",
                }}
                isExternal
              >
                <Center
                  borderWidth={"thin"}
                  border={"2px"}
                  borderColor={"#000"}
                  rounded={"2xl"}
                  w={"full"}
                  h={"16"}
                  cursor={"pointer"}
                >
                  <HStack>
                    <Image
                      src={playstore}
                      objectFit={"contain"}
                      w={"10"}
                      h={"10"}
                    />
                    <VStack align={"flex-start"} spacing={0}>
                      <Text
                        color={"#000"}
                        fontSize={{ base: 12, md: 13 }}
                        fontWeight={"normal"}
                      >
                        Get it on
                      </Text>
                      <Text
                        color={"#000"}
                        fontSize={{ base: 24, md: 27 }}
                        fontWeight={"normal"}
                        lineHeight={"6"}
                      >
                        Google Play
                      </Text>
                    </VStack>
                  </HStack>
                </Center>
              </Link>
            </Stack>
          </VStack>
        </Stack>
        <Stack
          bg={"#fff"}
          w={"full"}
          align={"center"}
          justify={"center"}
          py={"10"}
        >
          <Stack
            w={{ base: "sm", md: "xl", lg: "container.xl" }}
            justifyContent={"center"}
            alignItems={{ base: "flex-end", md: "center", lg: "flex-end" }}
          >
            <Grid
              templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
              gap={6}
              w={{ base: "xs", md: "lg" }}
            >
              {instruction.map((e, index) => (
                <HStack key={index} spacing={4}>
                  <Image src={e.img} objectFit={"contain"} w={"6"} h={"6"} />
                  <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
                    {e.desc}
                  </Text>
                </HStack>
              ))}
            </Grid>
          </Stack>
        </Stack>
      </VStack>
      {/* ------------ Company solutions ------------- */}
      <VStack
        w={"full"}
        h={{ base: "max-content", md: "max-content", lg: "550px" }}
        // py={{ base: "16", md: "10", lg: "0" }}
        backdropFilter={"auto"}
        backdropBrightness="15%"
        position={"relative"}
        align={"center"}
        justify={"center"}
        // spacing={0}
      >
        <Center
          display={{ base: "none", md: "none", lg: "flex" }}
          bg={"transparent"}
          w={"full"}
          h={"full"}
          backgroundImage={{ base: bg3, lg: bg3 }}
          backgroundRepeat="no-repeat"
          backgroundSize={"cover"}
          backgroundPosition={"center"}
          position={"absolute"}
          right={0}
          top={0}
          zIndex={"hide"}
          as={motion.svg}
          variants={{
            offscreen: {
              opacity: 0,
              x: 200,
            },
            onscreen: {
              opacity: 1,
              x: 0,
              transition: {
                type: "easeInOut",
                bounce: 1,
                duration: 1,
              },
            },
          }}
          initial="offscreen"
          whileInView={"onscreen"}
          viewport={{ once: true }}
        />
        <Image
          display={{ base: "none", md: "flex" }}
          src={bubbleBlack}
          objectFit={"contain"}
          w={"32"}
          position={"absolute"}
          left={20}
          top={10}
          zIndex={"hide"}
          as={motion.img}
          variants={{
            offscreen: {
              opacity: 0,
              x: -200,
            },
            onscreen: {
              opacity: 1,
              x: 0,
              transition: {
                type: "easeInOut",
                bounce: 1,
                duration: 1,
              },
            },
          }}
          initial="offscreen"
          whileInView={"onscreen"}
          viewport={{ once: true }}
        />
        <Image
          display={{ base: "none", md: "flex" }}
          src={bubbleBlack}
          objectFit={"contain"}
          w={"xs"}
          position={"absolute"}
          left={40}
          bottom={5}
          zIndex={"hide"}
          as={motion.img}
          variants={{
            offscreen: {
              opacity: 0,
              x: -300,
            },
            onscreen: {
              opacity: 1,
              x: 0,
              transition: {
                type: "easeInOut",
                bounce: 0.4,
                duration: 1,
              },
            },
          }}
          initial="offscreen"
          whileInView={"onscreen"}
          viewport={{ once: true }}
        />

        <VStack
          spacing={"16"}
          maxW={"container.xl"}
          w={{ base: "xs", md: "container.xl" }}
          py={{ base: "16", md: "10", lg: "0" }}
          as={motion.div}
          variants={{
            offscreen: {
              opacity: 0,
              y: 100,
            },
            onscreen: {
              opacity: 1,
              y: 0,
              transition: {
                type: "easeInOut",
                bounce: 0.4,
                duration: 1,
              },
            },
          }}
          initial="offscreen"
          whileInView={"onscreen"}
          viewport={{ once: true }}
        >
          <Heading as="h3" size="lg" fontWeight={"medium"} color={"#fff"}>
            Бидний үнэт зүйлс
          </Heading>
          <Stack
            direction={{ base: "column", md: "column", lg: "row" }}
            spacing={16}
            w={"full"}
            justify={"space-between"}
            align={"center"}
          >
            {companySolution.map((e, index) => (
              <VStack spacing={{ base: 2, md: 4, lg: 6 }} key={index} w={"xs"}>
                <Image src={e.img} objectFit={"contain"} w={"16"} h={"16"} />
                <Text color={"#fff"} fontSize={18} fontWeight={"medium"}>
                  {e.title}
                </Text>
                <Text
                  color={"#fff"}
                  fontSize={15}
                  fontWeight={"normal"}
                  textAlign={"center"}
                >
                  {e.desc}
                </Text>
              </VStack>
            ))}
          </Stack>
        </VStack>
      </VStack>
    </VStack>
  );
};

const system = [
  {
    title: "Хяналт",
    icon: require("../assets/icons/icon1.png"),
  },
  {
    title: "Удирдлага",
    icon: require("../assets/icons/icon2.png"),
  },
  {
    title: "Автоматжуулалт",
    icon: require("../assets/icons/icon3.png"),
  },
  {
    title: "Дүн шинжилгээ",
    icon: require("../assets/icons/icon4.png"),
  },
];

const chance = [
  {
    img: require("../assets/icons/icon2.png"),
    title: "Удирдах боломж",
    desc: "Хүссэн газраасаа гэрэлтүүлэг, унтраалга, хөшиг, сэнс агааржуулалт, цахилгаан халаалт, дуут дохио гэх мэт цахилгаан төхөөрөмжүүдийг удирдах боломжтой.",
  },
  {
    img: require("../assets/icons/icon1.png"),
    title: "Хянах боломж",
    desc: "Хүссэн үедээ хүссэн газраасаа температур, чийгшил, агаарын чанар, хөдөлгөөн, гал, утаа, ус алдалт зэргийг VIOT системийг ашиглан ямар ч үед хянах боломжтой.",
  },
  {
    img: require("../assets/icons/icon5.png"),
    title: "Төвлөрүүлэн харах боломж",
    desc: "Өөрийн гэр, оффис, үйлдвэрийнхээ төхөөрөмжүүдийг нэг аппликейшинд хандаж, цэгцтэйгээр төвлөрүүлэн нэгэн зэрэг хянах, удирдах, автоматжуулах боломжтой.",
  },
  {
    img: require("../assets/icons/icon3.png"),
    title: "Автоматжуулах боломж",
    desc: "Олон дахин давтагдан хийгдэх удирдах болон хянах үйлдлийг автоматжуулж хүний оролцоог багасган, тоног төхөөрөмж өөр хоорондоо мэдээлэл солилцон ажиллах боломжтой.",
  },
  {
    img: require("../assets/icons/icon4.png"),
    title: "Дүн шинжилгээ хийх боломж",
    desc: "Аппликейшн-ээр дамжуулан өөрт цугласан өгөгдөлд тулгуурлан, халаалт, гэрэлтүүлэг, аюулгүй байдал, үр ашгийн тооцоолон дүн шинжилгээ хийж өөрийн амьдрах орчиноо тав тухтай байдал үр ашгийг нэмэгдүүлэх боломжтой.",
  },
  {
    img: require("../assets/icons/icon6.png"),
    title: "Хязгааргүй өргөтгөх боломж",
    desc: "Газар тариалан, хөдөө аж ахуй, уул уурхай, эрчим хүч, үйлдвэрлэл үйлчилгээ гэх мэт салбаруудад өргөтгөж хүний хөдөлмөрийг хөнгөвчлөх боломжтой.",
  },
];

const where = [
  {
    title: "Гэр",
    icon: require("../assets/icons/cate1.png"),
    image: require("../assets/images/img1.png"),
    desc: "Та гэрийнхээ тав тухтай орчныг бүрдүүлж, болзошгүй эрсдэл аюулаас санаа зоволгүй хэдхэн хормын дотор утас, компьютер-аа ашиглан тоног төхөөрөмж, цахилгаан хэрэгслээ хянаж, удирдаж мэдээлэл авах боломжтой.",
  },
  {
    title: "Үйлчилгээний орчин",
    icon: require("../assets/icons/cate2.png"),
    image: require("../assets/images/img2.png"),
    desc: "VIOT системийг ашиглан үйлчилгээний орчин, бусдын тав тух, сэтгэл ханамжийг сайжруулах, эрчим хүч эдийн засгийн бодит хэмнэлтийг мэдрэх, хүний хөдөлмөр цаг зарцуулалтыг багасгах, бараа материалын бүртгэл, илүү ухаалаг маркетинг хийх гэх мэт олон давуу талуудтай.",
  },
  {
    title: "Үйлдвэр",
    icon: require("../assets/icons/cate3.png"),
    image: require("../assets/images/img3.png"),
    desc: "Үйлдвэрлэлийн тоног төхөөрөмжийг VIOT системийг ашиглан автоматжуулснаараа үйлдвэрлэлийн алдааг багасгах, үйлдвэрлэлийн бүтээмжийг нэмэгдүүлэх ба ээлжгүй ажиллах зэрэг хүний хөдөлмөр үйлдлүүдийг хөнгөвчлөх зэрэг олон онцлог давуу талууд үүснэ.",
  },
  {
    title: "Газар тариалан",
    icon: require("../assets/icons/cate4.png"),
    image: require("../assets/images/img4.png"),
    desc: "Газар тариалан болон Хөдөө аж ахуйд VIOT системийг ашиглаж хөрсний чийгийг мэдэрч автомат усжуулалт хийх, хүлэмжид ялзмагийн  дулааныг хэмжих, байршил тогтоох, зэрэг үйл ажиллагаанд хүний хөдөлмөрийг хөнгөвчилж ажиллах боломж бүрдэж байгаа юм.",
  },
];

const instruction = [
  {
    desc: "Аюул эрсдэлгүй ажиллагаа",
    img: require("../assets/icons/ins1.png"),
  },
  {
    desc: "Эрүүл тав тухтай орчин",
    img: require("../assets/icons/ins2.png"),
  },
  {
    desc: "Энгийн ухаалаг хэрэглээ",
    img: require("../assets/icons/ins3.png"),
  },
  {
    desc: "Эрчим хүчний хэмнэлт",
    img: require("../assets/icons/ins4.png"),
  },
  {
    desc: "Хосолмол цогц ажиллагаа",
    img: require("../assets/icons/ins5.png"),
  },
  {
    desc: "Хоцрогдолгүй мэдэгдэл",
    img: require("../assets/icons/ins6.png"),
  },
];

const companySolution = [
  {
    img: require("../assets/icons/sol1.png"),
    title: "Баг хамт олон",
    desc: "Салбартаа олон жил ажилласан мэргэжлийн туршлагатай хамт олон",
  },
  {
    img: require("../assets/icons/sol2.png"),
    title: "Үл тасрах хөгжүүлэлт",
    desc: "Хэрэглэгчийн хэрэгцээ шаардлага тулгуурласан үл тасалдах хөгжүүлэлт",
  },
  {
    img: require("../assets/icons/sol3.png"),
    title: "Найдвартай ажиллагаа",
    desc: "Хамгийн сүүлийн үеийн шинэлэг технологи, үл тасалдах найдвартай ажиллагаа",
  },
];

export default Home;
