import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Instruction from "./pages/Instruction";
import Main from "./pages/Main";
import Product from "./pages/Product";
import Question from "./pages/Question";
import ProductDetail from "./pages/ProductDetail";
import Policy from "./pages/Policy";
import { Helmet } from "react-helmet";

const App = () => {
  return (
    <>
      <Helmet>
        <title>Virtuoso IoT</title>
        <meta
          name="description"
          content="Та манай системийг ашиглан өөрийн гэр, оффис, бизнесийн орчны тодорхой үзүүлэлтүүд болон цахилгаан тоноглолыг хянаж удирдан санаа зовох асуудлаа цөөлөөрэй."
        />
        <link rel="canonical" href="https://viot.mn/home" />
      </Helmet>

      <Router>
        <Main>
          <Switch>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route path="/product">
              <Product />
            </Route>
            <Route path="/product-detail">
              <ProductDetail />
            </Route>
            <Route path="/question">
              <Question />
            </Route>
            <Route path="/instruction">
              <Instruction />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/policy">
              <Policy />
            </Route>
            <Redirect to={"/home"} />
          </Switch>
        </Main>
      </Router>
    </>
  );
};

export default App;
